import React from 'react';
import { useRouter } from 'next/router';

import useFiltersAndOrder from '../../../hooks/useFiltersAndOrder';
import Link from '../link/Link';
import {
  getDataFromStorage,
  NameStorage,
  removeDataInStorage,
  setDataInStorage,
} from '../../../utils/services/localStorage.service';
import BannerNew from '../../../layouts/Header/BannerNew';

const productTypeTitleSection = ['libros', 'ebooks'];

interface ButtonAreaOfInterestSectionProps {
  productTypeTitle: string;
  hasIconRight?: boolean;
  areaOfInterestName?: string;
  category: string;
}

const ButtonAreaOfInterestSection = ({
  productTypeTitle,
  hasIconRight,
  areaOfInterestName,
  category,
}: ButtonAreaOfInterestSectionProps): JSX.Element => {
  const { push } = useRouter();
  const { resetFilter } = useFiltersAndOrder();

  const removeAreaOfInterest = (e: React.BaseSyntheticEvent): void => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (process.browser) {
      removeDataInStorage(NameStorage.areaOfInterest);
      removeDataInStorage(NameStorage.areaOfInterestName);
    }
    resetFilter();
    push(`/${productTypeTitle}`);
  };

  return productTypeTitleSection.includes(productTypeTitle) ? (
    <>
      <Link
        href="/areas"
        key="PageLink"
        selectArea
        buttonLike
        onClick={(): void => setDataInStorage(NameStorage.myurl, productTypeTitle)}
        className="rounded bordered cta areasButton"
        iconAction={removeAreaOfInterest}
        iconRight={hasIconRight}
        xLink={productTypeTitle}
        iconClass={hasIconRight ? 'close' : ''}
      >
        {areaOfInterestName ||
          getDataFromStorage(NameStorage.areaOfInterestName) ||
          'Selecciona un Área de interés'}
      </Link>

      {['book'].includes(category) ? <BannerNew className="bannerImg2" short /> : null}
    </>
  ) : null;
};

export default ButtonAreaOfInterestSection;
